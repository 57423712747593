// const mixpanelFranco = mixpanel.init(
//   process.env.NODE_ENV === "production"
//     ? "f210afed8c1f27937019df33263e222e"
//     : "",
//   {
//     debug: process.env.NODE_ENV !== "production",
//     track_pageview: true,
//   },
//   "franco"
// );

export default {
  install(Vue) {
    Vue.prototype.$mixpanelFranco = {
      identify: (id) => {
        console.log("Identify", id);
      },
      track: (event, data) => {
        console.log("Track", event, data);
      },
      people: {
        set: (data) => {
          console.log("People set", data);
        },
      },
    };
  },
};

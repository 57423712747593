import AdminService from "@/services/admin.service";
import Storage from "@/services/storage";
const storage = new Storage();

export const state = {
  referralData: [],
  referralDataUser: [],
  referralSettings: [],
};

export const mutations = {
  SET_REFERRAL_DATA(state, payload) {
    state.referralData = payload;
  },
  SET_REFERRAL_DATA_USER(state, payload) {
    state.referralDataUser = payload;
  },
  SET_REFERRAL_SETTINGS(state, payload) {
    state.referralSettings = payload;
  },
};
export const actions = {
  getReferrals({ commit }) {
    const admin = storage.getSavedState("auth.admin");
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/get/users/referral/details?country_code=${admin.country}`
      )
        .then(({ data }) => {
          if (data) {
            commit("SET_REFERRAL_DATA", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getReferralUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/referral/details/${payload.referrer_id}`)
        .then((data) => {
          if ({ data }) {
            commit("SET_REFERRAL_DATA_USER", data.data.data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  redeemReferral({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/toggle/referral/status`, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getReferralSettings({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/referral/settings`)
        .then(({ data }) => {
          if (data) {
            commit("SET_REFERRAL_SETTINGS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  updateReferralSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/store/referral/settings`, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};

export const getters = {
  getReferralData: (state) => state.referralData,
  getReferralDataUser: (state) => state.referralDataUser,
  getReferralSettings: (state) => state.referralSettings,
};
